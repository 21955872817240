

/******************************** BURGER ********************************************/

/* @media screen and (min-width: 1050px) {
  .bm-burger-button {
    display: none;
  }
} */


/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 30px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}


/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: auto !important ;
}

/* General sidebar styles */
.bm-menu {
  background: #a0a0a0;
  font-size: 1.15em;


 
  z-index: 10;
  background-color: rgb(222 227 255 / 15%);
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 54%);
  backdrop-filter: blur( 2px );
  -webkit-backdrop-filter: blur( 2px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(112, 112, 112, 0) !important ; 
}

/* Individual item */
.bm-item {
  display: inline-block;
  background-color: white;
  color: #000000;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}