

.lite{
    font-family: 'Literata', serif;
    font-weight: 300;
}

.divider{
    padding: 50px;
}

.sub-title{
    color:black;
    font-size: 20px;
    font-weight: 200;
}
.App-header {
    background-color: #050e1f;
    display: flex;
  }
  

.data-one{
color: #ff2eaa;
}

.body-container{
    display: flex;
}


.center-layout{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* margin-left: 215px; */
    padding: 0 30px;
}
@media screen and (max-width: 800px) {
    .center-layout{
        margin-left: 0;
    }
  }

.magent{
    color: #ec008c;
}
.red{
    color: red;
}



