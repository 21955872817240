form {
    font-size: 18px;
    max-width: 300px;
    margin-bottom: 50px;
}

.contact-header {
    text-align: center;
    margin: 0px 0 10px 0;
    font-size: 29px;
}

form button {
    cursor: pointer;
    display: block;
    background-color: #f5f5f5;
    font-size: 21px;
    font-weight: 300;
    width: 160px;
    margin: 20px auto;
    border-radius: 3px;
    border: solid 2px #050e1f;
    transition: box-shadow .2s, background-color .2s;
}

form button:hover {
    box-shadow: 4px 3px 6px #00000036;
    background-color:white;
}
form button:active {
    box-shadow: none;
    font-size: 20px;
}   

.contact-input {
    width: 100%;
    min-height: 25px;
    margin-bottom: 15px;
}

.file-uploader{
    margin: 0px 0 20px 0;
}

.auto-sql input[type=file]::file-selector-button {
    padding: 10px 4px;  
  }

/* li.menu-item {
    width: max-content;
} */

/* For <li> counter */
/* ::marker {
    font-weight: bold;
    color: #060062;
} */