.job-title{
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
.job-title-sub{
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: rgb(80, 80, 80);
}

.title-container {
  margin-bottom: 30px;
}

iframe {
  width: 100%;
}

.closed{
  height: 300px;
}

img{
  width: 100%;
}

li{
  margin-top: 10px;
}

.bold{
font-weight: 600;
}

.job-container{
  margin-bottom: 100px;
  max-width: 700px;
}
@media screen and (max-width: 800px) {
  .job-container{
      max-width: 500px;
  }
}

.job-content p {
  padding: 0 10px
}

.job-content {
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  padding-bottom: 10px;
  backdrop-filter: blur( 1px );
  -webkit-backdrop-filter: blur( 1px );
  border-radius: 10px;
}

.job-logo{
  max-width: 300px;
}
@media screen and (max-width: 800px) {
  .job-logo{
    max-width: 200px;
  }
}
.job-logo img{
  width: 100%;
}

a{
  text-decoration: none;
  font-size: 18px;
}

.grey{
  color: #676767;
}

.flex{
  display: flex;
}

.no-padd{
  padding: 0;
}