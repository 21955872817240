#controls {
    position: absolute;
    bottom: 16px;
    left: 16px;
    max-width: unset;
    transform: unset;
  }
  
  button {
    color: black;
    border-radius: 25px;
  }

  .dot{
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    background: #fff;
    --min-hotspot-opacity: 0;
  }

  .dim{
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.8);
    display: block;
    font-family: Futura, Helvetica Neue, sans-serif;
    font-size: 18px;
    font-weight: 700;
    max-width: 128px;
    overflow-wrap: break-word;
    padding: 0.5em 1em;
    position: absolute;
    width: max-content;
    height: max-content;
    transform: translate3d(-50%, -50%, 0);
    --min-hotspot-opacity: 0;
  }
  
 label {
    display: block;
    margin-bottom: 0px;
    float: left;
    margin-right: 10px;
    font-weight: bold;
  }
  
 div#color-controls {
    display: flex;
    justify-content: center;
}

div#controls {
    bottom: 1;
    background-color: #ffffff00;
    display: flex;
    align-items: center;
}

 button.btn {
    border: solid .5px #c8c8c8;
    background: #ffffff;
    color: black;
    border-radius: 0px;
    
    text-align: center;
    display: block;
    
}

  .show{
    --min-hotspot-opacity: 1;
  }

  .hide{
    display: none;
  }
  /* This keeps child nodes hidden while the element loads */
  :not(:defined) > * {
    display: none;
  }

  model-viewer {
    width: 100%;
    height: 300px;
    border-bottom: solid 2px #5d5d5d;
}

.qr-code{
    width: 100px;
}

.ar-container{
    display: flex;
    align-items: center;
}

.phone-icon {
    max-width: 50px;
}